body{
  background-color: #222222;
  color:white;
  height:100vh;
  margin:0;
  padding:0;
  display:flex;
  flex-flow:column;
}

.banner-logo{
  font-size:48px;
}
.welcome-footer{
  margin-top:10px;
  font-size:32px;
  text-align: center;
}

.navigation{
  display:flex;
  flex-flow:row;
  align-items:center;
  position:fixed;
  height:70px;
  width:100%;
  justify-content: space-between;
  background-color: black;
  z-index:2;
}

.navigation .link-button{
  font-size:18px;
  display:flex;
  flex-flow:row;
  align-items:center;
  color:white;
  
  padding:6px;
  padding-left:10px;
  padding-right:10px;
  border-radius:8px;
}

.navigation .button-panel{
  margin:0;
}

.navigation .banner-logo{
  margin-left:10px;
  font-size:24px;
  cursor:pointer;
  user-select: none;
}

.navigation .button-row{
  margin-right:10px;
}

.content{
  padding-top:70px;
  display:flex;
  flex-flow:column;
  align-items:center;
  overflow-y:auto;
  font-size:24px;
  align-self:flex-end;
  height:calc(100vh - 70px)
}

.content-page{
  margin-bottom:10%;
  width:100%;
  display:flex;
  flex-flow:column;
}

.panel-duo{
  display:flex;
  flex-flow:row;
  justify-content: space-between;
}

.text-panel{
  display:flex;
  flex-flow:column;
  padding:20px;
}

.list{
  display:flex;
  flex-flow:column;
  align-self:center;
}

.stack-panel{
  display:flex;
  flex-flow:column;
  align-items:center;
}

.button-row{
  display:flex;
  flex-flow:row;
  align-items:center;
}

.welcome-image-panel{
  text-align: center;
  width:100%;
}

.welcome-image-panel img{
  width:55%;
}

.image-panel{
  text-align: center;
  width:100%;
}

.image-panel img{
  width:50%;
}

.panel-duo .image-panel{
  width:50%;
}

.panel-duo .text-panel{
  width:50%;
}

.page-header {
  text-align: center;
  font-size:32px;
  margin-bottom:50px;
}

.button-panel{
  display:flex;
  margin-bottom:20px;
}

.link-button{
  margin: 10px;
  font-size:20px;
  display:flex;
  flex-flow:row;
  align-items:center;
  color:white;
  
  padding:6px;
  padding-left:10px;
  padding-right:10px;
  border-radius:8px;
}

.link-button:hover{
  filter:brightness(1.2);
  cursor:pointer;
  user-select:none;
}

.link-button.github{
  background-color:darkgreen;
}

.link-button.kofi{
  background-color:rgb(0, 88, 189);
}

.link-button img{
  margin-right:10px;
}

.link-button.github img{
  filter:invert();
}

::-webkit-scrollbar {
  display: block;
  width: 15px;
}
*:hover::-webkit-scrollbar {
  display: block;
}
::-webkit-scrollbar-track {
  background-color: black;
  border: 5px solid transparent;
  background-clip: padding-box;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  background: rgba(255,255,255,0.25);
  border: 5px solid transparent;
  background-clip: padding-box;
  border-radius: 20px;
}
::-webkit-scrollbar-thumb:vertical:hover { background-color: white }
::-webkit-scrollbar-thumb:vertical:active { background-color: white }

@media screen and (max-width:800px) {
  .panel-duo{
    display:flex;
    flex-flow:column;
    align-items:center;
    justify-content: space-between;
  }

  .link-button{
    margin: 10px;
    font-size:16px;
    display:flex;
    flex-flow:row;
    align-items:center;
    color:white;
    
    padding:6px;
    padding-left:10px;
    padding-right:10px;
    border-radius:8px;
  }

  .navigation .link-button{
    font-size:14px;
    display:flex;
    flex-flow:row;
    align-items:center;
    color:white;
    
    padding:6px;
    padding-left:10px;
    padding-right:10px;
    border-radius:8px;
  }

  .navigation .banner-logo{
    margin-left:10px;
    font-size:18px;
    cursor:pointer;
    user-select: none;
  }

  .panel-duo .text-panel{
    width:75%;
  }
}